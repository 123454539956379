import { Observable } from "rxjs";
import React, { useEffect, useState } from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withContext,
} from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Close, Done, Visibility, VisibilityOff } from "@material-ui/icons";
import FormCheckbox from "../form/FormCheckbox";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import Text, { ACTUAL } from "../ui-core/Text";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import VehicleChangeType from "../vehicles-core/VehicleChangeType";
import UploadPhotoDialog from "../upload-photo-dialog/UploadPhotoDialog";
import {
  isEqualData,
  isEqualDataIn,
  mapResponseData,
} from "../../helpers/DataUtils";
import { formatText, parsePhone } from "../../helpers/FormatUtils";
import {
  isValidObjectId,
  validateRoleAuthorities,
  validateString,
} from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import { getFileUrl } from "../../api/shared/FileApi";
import UserImagePlaceholder from "../../assets/user_placeholder.png";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import FormVehicleChips from "../form/FormVehicleChips";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import _ from "lodash";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { red } from "@material-ui/core/colors";
import FormChipAutoComplete from "../form/FormChipAutoComplete";

const driverGenderOptions = OrderedSet.of("male", "female");

const Statuses = OrderedSet([ACTIVE, INACTIVE]);

const isValidVehicle = fp.overSome([
  fp.flow(fp.get("name"), fp.isEmpty),
  isValidObjectId,
]);

const enhancer = compose(
  connect(state => ({
    hasAVVALPermission: hasUserPermission(state, "AVVAL_USER_CONNECTOR"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    photo: {
      maxWidth: "100%",
      maxHeight: "256px",
      objectFit: "contain",
    },
    rowMarginBottom: {
      marginBottom: 13,
    },
    includeButton: {
      padding: 8,
    },
    dedicatedDriver: { marginTop: "16px" },
    imageContainer: { minHeight: "256px" },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedVehicle: PropTypes.func,
      getVehiclePredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedVehicle: props.getCachedVehicle,
      getVehiclePredictions: props.getVehiclePredictions,
    }),
  ),
  reduxForm({
    form: "DriverForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      login: validateString(values.login)
        ? props.getLocalisationMessage("this_field_is_required")
        : null,
      vehicle:
        !isValidVehicle(values.vehicle) &&
        props.getLocalisationMessage("select_vehicle", "Select Vehicle"),
      warehouse: !isValidObjectId(values.warehouse) && "Select Warehouse",
      supplier:
        !isValidObjectId(values.supplier) &&
        props.getLocalisationMessage(
          "supplier_is_required",
          "Supplier is required",
        ),
      roles:
        validateRoleAuthorities(values.roles) &&
        props.getLocalisationMessage("role_is_required", "Role is required"),
    }),
  }),
  formValues(
    "vehicle",
    "supplier",
    "parentSupplier",
    "photo",
    "imageId",
    "dedicated",
    "status",
    "warehouse",
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onShowSelectImageDialog,
      stream: onShowSelectImageDialogStream,
    } = createEventHandler();

    const photoStream = propsStream
      .filter(props => fp.toFinite(props.imageId) > 0)
      .switchMap(props =>
        getFileUrl(props.imageId).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        onShowSelectImageDialogStream.startWith(false),
        photoStream,
        (props, showSelectImageDialog, photoResponse) => ({
          ...props,
          showSelectImageDialog,
          onShowSelectImageDialog,
          attachment: photoResponse.get("payload"),
          isAttachmentLoading: photoResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

const url = "http://avval.uz/App/avval_info.php";
const username = "logistika";
const password = "1sdWq12ds!sA74";

const DriverForm = ({
  handleSubmit,
  imageId,
  onDismiss,
  onShowSelectImageDialog,
  showSelectImageDialog,
  submitting,
  updateVehicle,
  vehicle,
  classes,
  change,
  getSupplierPredictions,
  getVehicle,
  dedicated,
  attachment,
  getLocalisationMessage,
  hasAVVALPermission,
  initialValues,
  isEdit,
  userRoles,
  isSuperAdmin,
  uid,
}) => {
  const userPostcode = _.get(initialValues, "postcode.name");
  const [isLoadUsers, setIsLoadUsers] = useState(true);
  const [usersAVVAL, setUsersAVVAL] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (hasAVVALPermission && isEdit && userPostcode) {
      setIsLoadUsers(true);
      fetch(url, {
        mode: "cors",
        method: "post",
        headers: new Headers({
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        }),
        body: JSON.stringify({ avval_users: { post_code: userPostcode } }),
      })
        .then(response => response.json())
        .then(res => {
          const tempAvvalUsers = _.get(res, "list", []).filter(x =>
            _.get(x, "u_name"),
          );
          if (tempAvvalUsers.length > 0) {
            setUsersAVVAL(tempAvvalUsers);
          }
        })
        .catch(error => showErrorMessage(error))
        .finally(() => setIsLoadUsers(false));
    }
  }, [userPostcode]);
  return (
    <form autoComplete="off">
      <PageLoading isLoading={submitting} />

      <UploadPhotoDialog
        open={showSelectImageDialog}
        onRequestClose={() => onShowSelectImageDialog(false)}
        initialValues={{
          photoId: imageId,
          photo: attachment,
        }}
        onSubmit={values => {
          change("imageId", values.photoId);
          onShowSelectImageDialog(false);
        }}
      />

      <FlexBox gutter={8} container={16}>
        <FlexBox flex={true} direction="column">
          <Card style={{ height: "100%" }}>
            <CardContent>
              <FormTextField
                name="login"
                disabled={!isSuperAdmin && isEdit}
                label={`${getLocalisationMessage("login")} *`}
                fullWidth={true}
                style={{ marginBottom: 18 }}
              />

              <FlexBox gutter={16} className={classes.rowMarginBottom}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="firstName"
                    disabled={!isSuperAdmin && isEdit}
                    fullWidth={true}
                    label={getLocalisationMessage("first_name", "First Name")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="lastName"
                    disabled={!isSuperAdmin && isEdit}
                    fullWidth={true}
                    label={getLocalisationMessage("last_name", "Last Name")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={16} className={classes.rowMarginBottom}>
                <FlexBox flex={true}>
                  <FormTextField
                    fullWidth={true}
                    name="phone"
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                    parse={parsePhone}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormDateField
                    name="birthDate"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "birth_date",
                      "Birth Date",
                    )}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={16} className={classes.rowMarginBottom}>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="gender"
                    autoWidth={true}
                    fullWidth={true}
                    formatOption={option =>
                      getLocalisationMessage(option) || formatText(option)
                    }
                    label={getLocalisationMessage("gender", "Gender")}
                    options={driverGenderOptions}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="status"
                    autoWidth={true}
                    fullWidth={true}
                    options={Statuses}
                    formatOption={x =>
                      getLocalisationMessage(x) || formatText(x)
                    }
                    label={`${getLocalisationMessage("status", "Status")} *`}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={16} className={classes.rowMarginBottom}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="email"
                    label={getLocalisationMessage("email")}
                    fullWidth={true}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    fullWidth={true}
                    label={`${getLocalisationMessage(
                      "password",
                      "Password",
                    )} *`}
                    withOutInputProps={false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={16} className={classes.rowMarginBottom}>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    name="warehouse"
                    fullWidth={true}
                    label={getLocalisationMessage("warehouse", "Warehouse")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox flex={true} direction="column">
          <Card style={{ height: "100%" }}>
            <CardContent>
              <FlexBox flex={true}>
                <FlexBox flex={true} direction="column">
                  <FlexBox>
                    <ListSubheader>
                      {getLocalisationMessage("photo", "Photo")}
                    </ListSubheader>
                  </FlexBox>

                  <FlexBox justify="center" className={classes.imageContainer}>
                    {attachment && (
                      <img
                        className={classes.photo}
                        alt="Driver"
                        src={attachment}
                      />
                    )}
                    {!attachment && (
                      <img
                        className={classes.photo}
                        alt="Driver"
                        src={UserImagePlaceholder}
                      />
                    )}
                  </FlexBox>
                  <FlexBox>
                    <FlexBox gutter={16} flex={true} direction="column">
                      <FlexBox>
                        <FlexBox gutter={16} flex={true}>
                          {attachment && (
                            <FlexBox flex={true} justify="center">
                              <Button
                                onClick={() => onShowSelectImageDialog(true)}
                              >
                                {getLocalisationMessage(
                                  "change_photo",
                                  "Change Photo",
                                )}
                              </Button>
                            </FlexBox>
                          )}
                          {attachment && (
                            <FlexBox flex={true} justify="center">
                              <Button onClick={() => change("imageId", null)}>
                                {getLocalisationMessage(
                                  "remove_photo",
                                  "Remove Photo",
                                )}
                              </Button>
                            </FlexBox>
                          )}
                          {!attachment && (
                            <FlexBox flex={true} justify="center">
                              <Button
                                onClick={() => onShowSelectImageDialog(true)}
                              >
                                {getLocalisationMessage(
                                  "select_photo",
                                  "Select Photo",
                                )}
                              </Button>
                            </FlexBox>
                          )}
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox flex={true} direction="column">
          <Card style={{ flex: "1 1 auto", height: "100%" }}>
            <CardContent>
              {getSupplierPredictions && (
                <FlexBox gutter={16} className={classes.rowMarginBottom}>
                  <FlexBox flex={true}>
                    <FormSupplierAutoComplete
                      name="supplier"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "supplier",
                        "Supplier",
                      )} *`}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              )}

              {hasAVVALPermission && isEdit && userPostcode && (
                <FlexBox
                  flex={true}
                  direction="column"
                  className={classes.rowMarginBottom}
                >
                  <FlexBox>
                    <FormSelectField
                      style={{ minWidth: 240 }}
                      loading={isLoadUsers}
                      disabled={usersAVVAL.length === 0 || uid}
                      name="avvalUser"
                      fullWidth={true}
                      label={getLocalisationMessage("avval_users")}
                      withSuccessIcon={true}
                      options={usersAVVAL}
                      formatOption={v => _.get(v, "u_name")}
                      compareOptions={isEqualDataIn("uid")}
                    />
                  </FlexBox>
                  {usersAVVAL.length === 0 && (
                    <strong style={{ color: red[500] }}>
                      {getLocalisationMessage("avval_users_not_found")}
                    </strong>
                  )}
                </FlexBox>
              )}

              <FlexBox gutter={16} className={classes.rowMarginBottom}>
                <FlexBox flex={true}>
                  <FormChipAutoComplete
                    maxHeight={150}
                    name="roles"
                    margin="normal"
                    fullWidth={true}
                    options={userRoles}
                    formatOption={option =>
                      _.toUpper(
                        getLocalisationMessage(
                          _.isString(option) ? option : option.name,
                        ) ||
                          formatText(_.isString(option) ? option : option.name),
                      )
                    }
                    label={`${getLocalisationMessage("role", "Role")} *`}
                    chipContainerStyle={{
                      overflowY: "auto",
                    }}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={16} className={classes.rowMarginBottom}>
                <FlexBox flex={true}>
                  <FormVehicleChips
                    name="vehicles"
                    fullWidth={true}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={`${getLocalisationMessage("vehicle")}`}
                  />
                </FlexBox>
              </FlexBox>

              {Boolean(getVehicle && updateVehicle) && (
                <FlexBox className={classes.rowMarginBottom}>
                  <VehicleChangeType
                    getVehicle={getVehicle}
                    updateVehicle={updateVehicle}
                    vehicleId={fp.toFinite(vehicle && vehicle.id)}
                  />
                </FlexBox>
              )}

              <FlexBox flex={true}>
                <FormCheckbox
                  name="pushStatus"
                  label={getLocalisationMessage("push_status", "Push Status")}
                />
              </FlexBox>

              <FlexBox flex={true} className={classes.dedicatedDriver}>
                <Text type={ACTUAL}>
                  {dedicated
                    ? getLocalisationMessage(
                        "dedicated_driver",
                        "Dedicated Driver",
                      )
                    : getLocalisationMessage(
                        "per_shipment_driver",
                        "Per Shipment Driver",
                      )}
                </Text>
              </FlexBox>

              {Boolean(dedicated) && (
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="startDateAsDedicated"
                    label={getLocalisationMessage(
                      "start_as_dedicated_driver",
                      "Start as dedicated driver",
                    )}
                  />
                </FlexBox>
              )}
            </CardContent>
          </Card>
        </FlexBox>
      </FlexBox>
      <Card>
        <FlexBox
          gutter={16}
          container={16}
          flex={true}
          justify={JUSTIFY_CENTER}
        >
          <FlexBox>
            <CustomButton
              startIcon={<Close />}
              variant={OUTLINED}
              color={SECONDARY}
              onClick={onDismiss}
            >
              {" "}
              {getLocalisationMessage("dismiss", "Dismiss")}{" "}
            </CustomButton>
          </FlexBox>
          <FlexBox>
            <CustomButton
              endIcon={<Done />}
              variant={CONTAINED}
              color={SECONDARY}
              onClick={handleSubmit}
            >
              {getLocalisationMessage("submit", "Submit")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </Card>
    </form>
  );
};
DriverForm.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  showSelectImageDialog: PropTypes.bool,
  onShowSelectImageDialog: PropTypes.func,
  getVehicle: PropTypes.func,
  updateVehicle: PropTypes.func,
  vehicle: PropTypes.object,
  dedicated: PropTypes.bool,
  imageId: PropTypes.number,
  attachment: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
  hasAVVALPermission: PropTypes.bool,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  uid: PropTypes.string,
  userRoles: PropTypes.instanceOf(OrderedSet),
};
export default enhancer(DriverForm);
